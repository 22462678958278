import { memo, useMemo } from 'react';
import {
  Button,
  Box,
  FormLabel,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  Text,
} from '@chakra-ui/react';
import { ChevronDown } from 'assets';
import { IClub } from 'types';
import { ALL_CLUBS } from '../';

type Props = {
  clubId: string;
  clubs: IClub[];
  onChange: (clubId: string) => void;
};

function ClubsMenu({ clubId, clubs, onChange }: Props): JSX.Element {
  const sortedClubs = useMemo(() => {
    return clubs.sort((a, b) => {
      if (a.createdAt.toDate() > b.createdAt.toDate()) {
        return -1;
      }

      return 1;
    });
  }, [clubs]);

  return (
    <Box>
      <FormLabel fontSize="xs" mb={1}>
        Select Club
      </FormLabel>
      <Menu>
        <MenuButton
          as={Button}
          colorScheme="gray"
          variant="outline"
          rightIcon={<ChevronDown />}
        >
          <Text fontSize="sm">
            {clubId === ALL_CLUBS && 'All Clubs'}
            {clubId !== ALL_CLUBS &&
              clubs.find((club) => club.id === clubId)?.name}
          </Text>
        </MenuButton>
        <MenuList>
          <MenuOptionGroup value={clubId} type="radio">
            <MenuItemOption
              value={ALL_CLUBS}
              onClick={() => onChange(ALL_CLUBS)}
            >
              All Clubs
            </MenuItemOption>
            {sortedClubs.map(({ id, name }) => {
              return (
                <MenuItemOption
                  key={id}
                  value={id}
                  onClick={() => onChange(id)}
                >
                  {name}
                </MenuItemOption>
              );
            })}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Box>
  );
}

export default memo(ClubsMenu);
