import { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel, TabPanels } from '@chakra-ui/react';
import { Layout } from 'components';
import {
  useFetchPatronsByClub,
  useFetchRecordsByClub,
  useFetchRewardsByClub,
} from 'hooks';
import { useGetBrewery } from 'contexts';
import { Features, IClub } from 'types';
import ClubActions from './ClubActions';
import ClubOverview from './ClubOverview';
import PatronSignupForm from './PatronSignupForm';
import ClubRewards from './ClubRewards';
import ClubBenefits from './ClubBenefits';
import PatronsTable from './PatronsTable';
import { db } from '../../firebase';

const tabNameToIndex: Record<string, number> = {
  dashboard: 0,
  patrons: 1,
  rewards: 2,
  benefits: 3,
  'patron-signup': 4,
};

const tabNames: string[] = [
  'dashboard',
  'patrons',
  'rewards',
  'benefits',
  'patron-signup',
];

export default function ClubPage(): JSX.Element {
  const { pane, uid } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [clubData, setClubData] = useState<IClub | undefined>(undefined);
  const [brewery] = useGetBrewery();
  const [patrons] = useFetchPatronsByClub(uid, { filterArchived: true });
  const [records] = useFetchRecordsByClub(uid);
  const [rewards] = useFetchRewardsByClub(uid);

  useEffect(() => {
    setTabIndex(tabNameToIndex[pane || 'dashboard']);
  }, [pane]);

  useEffect(() => {
    const clubRef = doc(db, 'clubs', uid!);

    let unsubscribe: () => void;

    if (uid) {
      unsubscribe = onSnapshot(clubRef, (clubDoc) => {
        setClubData({
          id: clubDoc.id,
          ...clubDoc.data(),
        } as IClub);

        setIsLoading(false);
      });
    }

    return () => unsubscribe();
  }, [uid]);

  function handleTabsChange(index: number) {
    setTabIndex(index);

    const pane = tabNames[index];
    window.history.pushState(null, '', `/auth/clubs/${uid}/${pane}`);
  }

  const breweryTracksBenefits = Boolean(
    brewery?.features?.includes(Features.BENEFITS_TRACKING),
  );
  const clubHasBenefits = Boolean(clubData?.benefits.length);
  const clubHasRewards = Boolean(clubData?.rewards.length);

  return (
    <Layout
      backUrl="/auth/clubs"
      isLoading={isLoading}
      pageDescription="View and edit a mug club."
      pageHeading={clubData?.name}
      ActionsComponent={<ClubActions club={clubData} />}
    >
      <Tabs
        colorScheme="green"
        index={tabIndex}
        isLazy
        onChange={handleTabsChange}
        size="sm"
        variant="line"
      >
        <TabList mb={4}>
          <Tab fontWeight="medium">Dashboard</Tab>
          <Tab fontWeight="medium">Patrons</Tab>
          <Tab fontWeight="medium" display={clubHasRewards ? '' : 'none'}>
            Rewards
          </Tab>
          <Tab
            fontWeight="medium"
            display={breweryTracksBenefits && clubHasBenefits ? '' : 'none'}
          >
            Benefits
          </Tab>
          <Tab fontWeight="medium">Patron Signup Form</Tab>
        </TabList>

        {clubData && (
          <TabPanels>
            <TabPanel p={0}>
              <ClubOverview
                club={clubData}
                patrons={patrons}
                records={records}
                rewards={rewards}
              />
            </TabPanel>

            <TabPanel p={0}>
              <PatronsTable
                club={clubData}
                patrons={patrons}
                records={records}
              />
            </TabPanel>

            <TabPanel p={0}>
              <ClubRewards club={clubData} rewards={rewards} />
            </TabPanel>

            <TabPanel p={0}>
              <ClubBenefits brewery={brewery} club={clubData} />
            </TabPanel>

            <TabPanel p={0}>
              <PatronSignupForm club={clubData} />
            </TabPanel>
          </TabPanels>
        )}
      </Tabs>
    </Layout>
  );
}
