import { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  MenuItem,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { archivePatron, updatePatron } from 'api';
import { useFetchPatronMembershipByClub } from 'hooks';
import { ArchiveIcon } from 'assets';
import { IClub, IPatron } from 'types';

type Props = {
  club: IClub;
  patron: IPatron;
};

export default function ArchivePatron({ club, patron }: Props): JSX.Element {
  const { archivedAt, id } = patron;
  const [membership] = useFetchPatronMembershipByClub(id, club.id, true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);
  const toast = useToast();
  const isArchived = Boolean(archivedAt);

  async function handleArchivePatron() {
    if (!membership) {
      return;
    }

    onClose();

    try {
      if (isArchived) {
        await updatePatron(id, {
          archivedAt: null,
        });
      } else {
        await archivePatron(id, membership.id);
      }

      toast({
        description: isArchived ? 'Patron restored.' : 'Patron archived.',
        status: 'success',
        title: 'Success',
      });
    } catch (error) {
      toast({
        description: `Failed to ${
          isArchived ? 'restore' : 'archive'
        } patron. Please try again.`,
        status: 'error',
        title: 'Uh Oh',
      });
    }
  }

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {isArchived ? 'Restore Archived' : 'Archive'} Patron
            </AlertDialogHeader>

            <AlertDialogBody>
              {isArchived
                ? 'Restoring an archived patron does not automatically reactivate their membership. You must reactivate their membership manually.'
                : 'Archiving a patron deactivates their active membership. You can always restore an archived patron later.'}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                onClick={onClose}
                variant="outline"
                colorScheme="gray"
                size="sm"
              >
                Cancel
              </Button>
              <Button
                onClick={handleArchivePatron}
                colorScheme={isArchived ? 'green' : 'red'}
                ml={2}
                size="sm"
              >
                {isArchived ? 'Restore' : 'Archive'}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <MenuItem icon={<ArchiveIcon />} onClick={onOpen}>
        {isArchived ? 'Restore Archived' : 'Archive'} Patron
      </MenuItem>
    </>
  );
}
