import { useCallback, useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { IPatron } from 'types';
import { db } from '../firebase';

type Options =
  | {
      filterArchived?: boolean;
      includeArchived?: boolean;
    }
  | undefined;

export default function useFetchPatronsByClub(
  clubId?: string,
  options?: Options,
): [IPatron[], boolean] {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [patronsData, setPatronsData] = useState<IPatron[]>([]);
  const { filterArchived = false, includeArchived = false } = options || {};

  const fetchPatrons = useCallback(async () => {
    if (!clubId) {
      setIsLoading(false);
      return;
    }

    const queryConstraints = [where('clubs', 'array-contains', clubId)];

    if (filterArchived) {
      queryConstraints.push(where('archivedAt', '==', null));
    }

    if (includeArchived) {
      queryConstraints.push(where('archivedAt', '!=', null));
    }

    const patronsCollection = collection(db, 'patrons');
    const q = query(patronsCollection, ...queryConstraints);

    try {
      const patronDocs = await getDocs(q);
      const patrons: IPatron[] = [];

      patronDocs.forEach((doc) => {
        patrons.push({
          id: doc.id,
          ...doc.data(),
        } as IPatron);
      });

      setPatronsData(patrons);
    } catch (error) {
      // @TODO: Error handling
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [clubId, filterArchived, includeArchived]);

  useEffect(() => {
    fetchPatrons();
  }, [clubId, fetchPatrons]);

  return [patronsData, isLoading];
}
