import {
  addDoc,
  collection,
  doc,
  runTransaction,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { IMembership, IPatron } from 'types';
import { db } from '../../firebase';

type CreatePatronParams = Omit<IPatron, 'id'>;

export async function createPatron(
  params: CreatePatronParams,
): Promise<IPatron> {
  const patronsCollection = collection(db, 'patrons');

  const patronDoc = await addDoc(patronsCollection, params);

  return {
    ...params,
    id: patronDoc.id,
  };
}

type UpdatePatronParams = Partial<
  Pick<
    IPatron,
    | 'archivedAt'
    | 'birthdayMonth'
    | 'email'
    | 'firstName'
    | 'lastName'
    | 'mugNumber'
    | 'phoneNumber'
    | 'shirtSize'
  >
>;

export async function updatePatron(
  patronId: string,
  params: UpdatePatronParams,
): Promise<void> {
  if (!patronId) {
    return;
  }

  const patronRef = doc(db, `patrons/${patronId}`);

  await updateDoc(patronRef, params);
}

export async function updatePatronNotes(
  patronId: string,
  {
    breweryId,
    notes,
  }: {
    breweryId: string;
    notes?: string;
  },
): Promise<void> {
  if (!patronId || !breweryId) {
    return;
  }

  const patronRef = doc(db, `patrons/${patronId}`);

  await updateDoc(patronRef, {
    [`notes.${breweryId}`]: notes || null,
  });
}

export async function archivePatron(
  id: string,
  membershipId: string,
): Promise<void> {
  if (!id || !membershipId) {
    return;
  }

  const patronRef = doc(db, `patrons/${id}`);
  const patronData: Pick<IPatron, 'archivedAt'> = {
    archivedAt: Timestamp.now(),
  };
  const membershipRef = doc(db, `memberships/${membershipId}`);
  const membershipData: Pick<IMembership, 'isActive'> = {
    isActive: false,
  };

  await runTransaction(db, async (transaction) => {
    transaction.update(patronRef, patronData);
    transaction.update(membershipRef, membershipData);
  });
}
