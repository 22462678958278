import { memo } from 'react';
import { Tr, Td } from '@chakra-ui/react';
import { PatronName } from 'components';
import { useFetchPatronMemberships } from 'hooks';
import { IPatron } from 'types';
import MembershipStatus from './MembershipStatus';

type Props = {
  clubId?: string;
  patron: IPatron;
  onClick: (patronId: string) => void;
  showMugNumbers?: boolean;
};

function PatronTableRow({
  clubId,
  patron,
  onClick,
  showMugNumbers,
}: Props): JSX.Element {
  const [memberships, isLoading] = useFetchPatronMemberships(patron.id);
  const { id, mugNumber, createdAt, lastUpdatedAt } = patron;
  const lastUpdated = lastUpdatedAt
    ? lastUpdatedAt.toDate().toLocaleDateString()
    : createdAt.toDate().toLocaleDateString();

  return (
    <Tr onClick={() => onClick(id)} cursor="pointer">
      <Td>
        <PatronName patron={patron} />
      </Td>
      {showMugNumbers && <Td>{mugNumber ? mugNumber : '-'}</Td>}
      <MembershipStatus
        clubId={clubId}
        isLoading={isLoading}
        memberships={memberships}
      />
      <Td>{createdAt.toDate().toLocaleDateString()}</Td>
      <Td>{lastUpdated}</Td>
    </Tr>
  );
}

export default memo(PatronTableRow);
