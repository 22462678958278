import { doc, Timestamp } from 'firebase/firestore';
import { IClub, IMembership, IPatron } from '../../../../types';
import { unformatPhoneNumber } from '../../../../utils';
import { db } from '../../../../firebase';

export type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  birthdayMonth: string;
  mugNumber: string;
  shirtSize: string;
  clubId: string;
};

export function formatPatronData(
  formData: FormData,
  breweryId: string,
  clubId: string,
): Omit<IPatron, 'id'> {
  const breweryRef = doc(db, 'breweries', breweryId);

  return {
    archivedAt: null,
    birthdayMonth: formData.birthdayMonth || null,
    breweryId,
    breweryRef,
    canReceiveSms: false,
    clubs: [clubId],
    createdAt: Timestamp.now(),
    email: formData.email,
    firstName: formData.firstName,
    lastName: formData.lastName,
    lastUpdatedAt: Timestamp.now(),
    mugNumber: parseMugNumber(formData.mugNumber),
    notes: {
      breweryId: null,
    },
    phoneNumber: unformatPhoneNumber(formData.phoneNumber),
    shirtSize: formData.shirtSize || null,
  };
}

export function formatMembershipData(
  breweryId: string,
  club: IClub,
  patronId: string,
): Omit<IMembership, 'id'> {
  const { endDate, id } = club;

  return {
    activatedAt: null,
    breweryId,
    clubId: id,
    createdAt: Timestamp.now(),
    expiresAt: endDate ? endDate : null,
    isActive: false,
    patronId,
  };
}

function parseMugNumber(value: string): number | null {
  if (!value) {
    return null;
  }

  return parseInt(value);
}
