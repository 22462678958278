import { useMemo } from 'react';
import { IClub, IPatron } from 'types';

interface IPatronWithClubName extends IPatron {
  clubNames: string[];
}

export function mapPatronsToClubNames(
  patrons: IPatron[],
  clubs: IClub[],
): IPatronWithClubName[] {
  return patrons.map((patron) => {
    const clubNames: string[] = [];

    patron.clubs.forEach((clubId) => {
      const clubName = clubs.find((club) => club.id === clubId)?.name;

      if (clubName) {
        clubNames.push(clubName);
      }
    });

    return {
      ...patron,
      clubNames,
    };
  });
}

type SearchPatronsParams = {
  clubId?: string;
  orderedDescending: boolean;
  patrons: IPatron[];
  searchValue: string;
  showArchivedPatrons: boolean;
  sorter: string;
};

export function useSearchPatrons({
  clubId,
  orderedDescending,
  patrons,
  searchValue,
  showArchivedPatrons,
  sorter,
}: SearchPatronsParams): IPatron[] {
  return useMemo(
    () =>
      patrons
        ?.filter((patron) => {
          if (!showArchivedPatrons && Boolean(patron.archivedAt)) {
            return false;
          }

          if (showArchivedPatrons && !Boolean(patron.archivedAt)) {
            return false;
          }

          if (clubId && !patron.clubs.includes(clubId)) {
            return false;
          }

          const patronFullName =
            `${patron.firstName} ${patron.lastName}`.toLowerCase();
          if (patronFullName.includes(searchValue.toLowerCase())) {
            return true;
          }

          return false;
        })
        .sort((patronA: IPatron, patronB: IPatron) => {
          if (orderedDescending) {
            // @ts-ignore
            if (patronA[sorter]! > patronB[sorter]) {
              return -1;
            }

            return 1;
          }

          // @ts-ignore
          if (patronA[sorter]! > patronB[sorter]) {
            return 1;
          }

          return -1;
        }),
    [
      clubId,
      orderedDescending,
      patrons,
      searchValue,
      showArchivedPatrons,
      sorter,
    ],
  );
}
