import { memo, useMemo } from 'react';
import { useFetchPatronMembershipsByClub, useFetchPatronsByClub } from 'hooks';
import { patronMembershipStatus } from 'utils';
import { IClub, IMembership, IPatron } from 'types';
import Metric from './Metric';

type Props = {
  club: IClub;
};

function PatronStatusMetrics({ club }: Props): JSX.Element {
  const [memberships, loading] = useFetchPatronMembershipsByClub(club.id);
  const [archivedPatrons, loadingArchived] = useFetchPatronsByClub(club.id, {
    includeArchived: true,
  });
  const { active, overdue, inactive } = useMemo(
    () => getMembershipCountsByStatus(memberships, archivedPatrons),
    [memberships, archivedPatrons],
  );
  const isLoading = loading || loadingArchived;

  return (
    <>
      <Metric
        label="Active Memberships"
        loading={isLoading}
        number={active}
        description="# of active memberships"
      />
      <Metric
        label="Overdue Memberships"
        loading={isLoading}
        number={overdue}
        description="# of overdue memberships"
      />
      <Metric
        label="Inactive Memberships"
        loading={isLoading}
        number={inactive}
        description="# of inactive memberships"
      />
    </>
  );
}

function getMembershipCountsByStatus(
  memberships: IMembership[],
  archivedPatrons: IPatron[],
): Record<string, number> {
  const active = memberships.filter(
    (membership) => patronMembershipStatus(membership) === 'active',
  ).length;
  const overdue = memberships.filter(
    (membership) => patronMembershipStatus(membership) === 'overdue',
  ).length;
  const inactive =
    memberships.filter(
      (membership) => patronMembershipStatus(membership) === 'inactive',
    ).length - (archivedPatrons?.length || 0);

  return {
    active,
    overdue,
    inactive,
  };
}

export default memo(PatronStatusMetrics);
