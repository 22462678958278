import { memo } from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import { usePatronContext } from '../Context';

function PatronArchivedAlert(): JSX.Element | null {
  const { patron } = usePatronContext();

  if (!patron?.archivedAt) {
    return null;
  }

  return (
    <Alert status="warning" mb={6} rounded="md">
      <AlertIcon />
      <AlertTitle>Patron Archived</AlertTitle>
      <AlertDescription>
        This patron was archived on{' '}
        {patron.archivedAt.toDate().toLocaleDateString()}. Restore the patron in
        order to reactivate their club membership.
      </AlertDescription>
    </Alert>
  );
}

export default memo(PatronArchivedAlert);
