import { memo } from 'react';
import { FormControl, FormLabel, Switch } from '@chakra-ui/react';

type Props = {
  onChange: (value: boolean) => void;
  isChecked: boolean;
};

function ArchivedPatronsSwitch({ onChange, isChecked }: Props): JSX.Element {
  function handleOnChange({ target }: React.ChangeEvent<HTMLInputElement>) {
    onChange(target.checked);
  }

  return (
    <FormControl>
      <FormLabel fontSize="xs" mb={1} mr={0}>
        Archived Patrons
      </FormLabel>
      <Switch isChecked={isChecked} onChange={handleOnChange} />
    </FormControl>
  );
}

export default memo(ArchivedPatronsSwitch);
