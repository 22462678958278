import { memo } from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import { ClubStatus, IClub } from 'types';

type Props = {
  club: IClub;
};

function ClubStatusAlert({ club }: Props): JSX.Element | null {
  const { status } = club;

  if (status === ClubStatus.ACTIVE) {
    return null;
  }

  if (status === ClubStatus.DRAFT) {
    return (
      <Alert status="info" mb={6} rounded="md">
        <AlertIcon />
        <AlertTitle>Draft Club</AlertTitle>
        <AlertDescription>
          You are viewing a patron's membership in a draft club.
        </AlertDescription>
      </Alert>
    );
  }

  if (status === ClubStatus.COMPLETE) {
    return (
      <Alert status="warning" mb={6} rounded="md">
        <AlertIcon />
        <AlertTitle>Completed Club</AlertTitle>
        <AlertDescription>
          You are viewing a patron's membership in a completed club.
        </AlertDescription>
      </Alert>
    );
  }

  return null;
}

export default memo(ClubStatusAlert);
