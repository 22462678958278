import { memo, useMemo } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { IClub, IPatron, IPatronReward } from 'types';
import Metric from './Metric';
import PatronStatusMetrics from './PatronStatusMetrics';

interface IParams {
  club: IClub;
  patrons: IPatron[];
  recordsCount: number;
  rewards: IPatronReward[];
}

function getRewardsRedemptionRatio(
  redeemedRewardsCount: number,
  rewardsCount: number,
): string {
  if (rewardsCount === 0) {
    return '0%';
  }

  return `${Math.floor((redeemedRewardsCount / rewardsCount) * 100)}%`;
}

function ClubMetrics({
  club,
  patrons,
  recordsCount,
  rewards,
}: IParams): JSX.Element {
  const estimatedRecordsRevenue = `$${(recordsCount * 8).toLocaleString()}`;
  const estimatedMembershipRevenue = `$${(
    patrons.length * (club.cost || 0)
  ).toLocaleString()}`;
  const redeemedRewardsCount = useMemo(
    () => rewards.filter((reward) => reward.redeemed).length,
    [rewards],
  );
  const rewardsRedemptionRatio = useMemo(
    () => getRewardsRedemptionRatio(redeemedRewardsCount, rewards.length),
    [redeemedRewardsCount, rewards.length],
  );
  const clubHasRewards = club.rewards.length > 0;
  const totalPatrons = useMemo(
    () => patrons.filter((patron) => !Boolean(patron.archivedAt)).length,
    [patrons],
  );

  return (
    <Box>
      <Box mb={4}>
        <Text fontWeight="medium">Club Metrics</Text>
        <Text color="gray.500" fontSize="sm">
          A snapshot of your club's performance.
        </Text>
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr 1fr 1fr 1fr 1fr 1fr"
        gap={2}
      >
        <Metric
          label="Total Patrons"
          number={totalPatrons}
          description="Number of patrons in this club"
        />

        <PatronStatusMetrics club={club} />

        {clubHasRewards && (
          <>
            <Metric
              label="Total Visits"
              number={recordsCount.toLocaleString()}
              description="# of recorded visits"
            />

            <Metric
              label="Rewards Earned"
              number={rewards.length?.toLocaleString()}
              description="# of earned rewards"
            />

            <Metric
              description="Redeemed vs. earned rewards"
              label="Redemption Rate"
              number={rewardsRedemptionRatio}
            />

            <Metric
              description="Assumes an $8 pour per visit"
              label="Visits Revenue (Est.)"
              number={estimatedRecordsRevenue}
            />
          </>
        )}

        <Metric
          description="Total membership revenue"
          label="Membership Revenue"
          number={estimatedMembershipRevenue}
        />
      </Box>
    </Box>
  );
}

export default memo(ClubMetrics);
