import { memo, useMemo } from 'react';
import {
  InputGroup,
  InputLeftElement,
  Input,
  Box,
  Button,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { SearchIcon } from 'assets';
import ClubsMenu from './ClubsMenu';
import { IClub } from 'types';
import ArchivedPatronsSwitch from './ArchivedPatronsSwitch';

import { ALL_CLUBS } from '..';

type Props = {
  clubs: IClub[];
  clubId: string;
  onChangeArchivedPatrons: (value: boolean) => void;
  onChangeClub: (clubId: string) => void;
  onResetFilters: () => void;
  onSearch: (value: string) => void;
  searchValue: string;
  showArchivedPatrons: boolean;
};

function Filters({
  clubs,
  clubId,
  onChangeArchivedPatrons,
  onChangeClub,
  onResetFilters,
  onSearch,
  searchValue,
  showArchivedPatrons,
}: Props): JSX.Element {
  const showResetButton = useMemo(
    () => Boolean(searchValue || clubId !== ALL_CLUBS || showArchivedPatrons),
    [clubId, searchValue, showArchivedPatrons],
  );

  return (
    <Box display="flex" alignItems="center" gap={4}>
      <Box
        alignItems="baseline"
        display="grid"
        gridTemplateColumns="350px max-content max-content"
        gap={4}
      >
        <FormControl>
          <FormLabel fontSize="xs" mb={1}>
            Search Patrons
          </FormLabel>
          <InputGroup>
            <InputLeftElement children={<SearchIcon />} />
            <Input
              value={searchValue}
              placeholder="Search patrons by name"
              onChange={({ target }) => onSearch(target.value)}
            />
          </InputGroup>
        </FormControl>

        {clubs?.length > 1 && (
          <ClubsMenu clubs={clubs} onChange={onChangeClub} clubId={clubId} />
        )}

        <ArchivedPatronsSwitch
          isChecked={showArchivedPatrons}
          onChange={onChangeArchivedPatrons}
        />
      </Box>

      {showResetButton && (
        <Button
          variant="ghost"
          size="sm"
          onClick={onResetFilters}
          colorScheme="gray"
        >
          Reset Filters
        </Button>
      )}
    </Box>
  );
}

export default memo(Filters);
