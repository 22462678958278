import { Box, Button, useDisclosure } from '@chakra-ui/react';
import AddPatronFormDrawer from './AddPatronFormDrawer';
import ImportPatronsNotification from './ImportPatronsNotification';
import { IBrewery, IClub } from '../../../types';

interface IProps {
  brewery?: IBrewery;
  clubs?: IClub[];
}

export default function PatronsActions({
  brewery,
  clubs,
}: IProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isImportOpen,
    onOpen: onImportOpen,
    onClose: onImportClose,
  } = useDisclosure();

  return (
    <Box display="flex" gap={2}>
      <AddPatronFormDrawer
        isOpen={isOpen}
        onClose={onClose}
        breweryId={brewery?.id}
        clubs={clubs}
      />

      <ImportPatronsNotification
        isOpen={isImportOpen}
        onClose={onImportClose}
      />

      <Button size="sm" onClick={onOpen}>
        Add Patron
      </Button>

      <Button
        size="sm"
        variant="outline"
        colorScheme="gray"
        onClick={onImportOpen}
      >
        Import Patrons
      </Button>
    </Box>
  );
}
