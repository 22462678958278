import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { EmptyState, PatronName, SortableTableHeader } from 'components';
import { IClub, IPatron, IRecord } from 'types';
import PatronsTableMembershipStatus from './PatronsTableMembershipStatus';
import { mapPatronsToRecords, orderPatronsByRecordCount } from './utils';

type Props = {
  club: IClub;
  emptyStateButtonText?: string;
  emptyStateOnClick?: () => {};
  patrons: IPatron[];
  records: IRecord[];
};

export default function PatronsTable({
  club,
  emptyStateButtonText,
  emptyStateOnClick,
  patrons,
  records,
}: Props): JSX.Element {
  const [orderedDescending, setOrderedDescending] = useState<boolean>(true);
  const navigate = useNavigate();
  const patronsInClubWithRecords = useMemo(
    () => mapPatronsToRecords(patrons, records),
    [patrons, records],
  );
  const patronsOrderedByRecordCount = useMemo(
    () =>
      orderPatronsByRecordCount(patronsInClubWithRecords, orderedDescending),
    [patronsInClubWithRecords, orderedDescending],
  );

  const clubHasRewards = Boolean(club.rewards.length);

  function handleOnChangeOrder() {
    setOrderedDescending(!orderedDescending);
  }

  function handleClick(id: string) {
    navigate(`/auth/patrons/${id}`);
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Heading as="h2" fontSize="lg">
          Patrons ({patronsOrderedByRecordCount.length} total)
        </Heading>
      </Box>

      {patronsOrderedByRecordCount?.length ? (
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Mug Number</Th>
              <Th>Status</Th>
              <Th>Membership Expires</Th>
              {clubHasRewards && (
                <>
                  <SortableTableHeader
                    onClick={handleOnChangeOrder}
                    active
                    orderedDescending={orderedDescending}
                  >
                    Records
                  </SortableTableHeader>
                  <Th>Last Active</Th>
                </>
              )}
            </Tr>
          </Thead>
          <Tbody>
            {patronsOrderedByRecordCount.map((patron) => (
              <Tr
                key={patron.id}
                onClick={() => handleClick(patron.id)}
                cursor="pointer"
                _hover={{
                  backgroundColor: 'gray.50',
                }}
              >
                <Td>
                  <PatronName patron={patron} />
                </Td>
                <Td>{patron.mugNumber ? patron.mugNumber : '-'}</Td>

                <PatronsTableMembershipStatus club={club} patron={patron} />

                {clubHasRewards && (
                  <>
                    <Td>{patron.records.length}</Td>
                    <Td>
                      {patron.lastActive?.toDate().toLocaleDateString() ||
                        'N/A'}
                    </Td>
                  </>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <EmptyState
          buttonText={emptyStateButtonText}
          onClick={emptyStateOnClick}
        />
      )}
    </>
  );
}
